/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { memo, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-daisyui';
import AddFirmModal from '@/components/UsersAccordionList/AddFirmModal';
import { FirmsWithUserDTO, AccountData } from '@shared/types';
import { openHubspotUser } from '@/utils/hubspot';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import { Accordion } from '@/components/Accordion/Accordion';
import { IUserContext, useUserContext } from '@/context/UserContext';

interface Props {
  firms: FirmsWithUserDTO[];
  size?: 'sm' | 'md';
  showHubspotLink?: boolean;
  clientData: AccountData;
}

function FirmsAccordionList({
  firms = [],
  size = 'md',
  showHubspotLink,
  clientData,
}: Props) {
  const { auth }: IUserContext = useUserContext();
  const [showModal, setShowModal] = useState(false);

  const fontSize = size === 'md' ? 'text-base' : 'text-sm';

  if (firms.length <= 0) {
    return <h2>No Firms</h2>;
  }
  return (
    <>
      <ToastContainer />
      <div className='flex items-center space-x-4'>
        {' '}
        {/* Added flex and space-x-4 for spacing */}
        <Button
          type='submit'
          title='login'
          color='accent'
          size='sm'
          className='btn btn-accent bg-transparentp-2 m-2 ml-0'
          data-testid='add-firm-button'
          onClick={() => setShowModal((prev) => !prev)}
        >
          Add Firm
        </Button>
        {showModal ? (
          <AddFirmModal
            company={{
              id: clientData?.id,
              client_name: '',
              hubspot_id: clientData?.hubspot_id,
            }}
            isOpen={showModal}
            onClose={() => setShowModal(false)}
          />
        ) : null}
      </div>

      {firms.map((firm, index) => (
        <Accordion
          key={`${firm.id}_${firm?.firm_name}`}
          showBottomSeparator
          open={index === 0}
          toggler={
            <div className='flex'>
              <span className={`${fontSize} font-bold capitalize`}>
                {`${firm?.firm_name}`}
              </span>
            </div>
          }
        >
          <div className='flex'>
            <div className='flex-grow'>
              <div className='mt-2'>
                <span className={`${fontSize} text-gray-400 mr-2`}>
                  Domain:
                </span>
                <span className={`${fontSize} font-bold`}>
                  <a
                    href={
                      firm.domain_name?.startsWith('http://') ||
                      firm.domain_name?.startsWith('https://')
                        ? firm.domain_name
                        : `${
                            firm.domain_name
                              ? `https://${firm.domain_name}`
                              : ''
                          }`
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {firm.domain_name}
                  </a>
                </span>
              </div>
              <div className='mt-2 ml-2'>
                <Accordion
                  key={`accordion-user-${firm.id}_${firm.firm_name}`}
                  showBottomSeparator={false}
                  open={index === 0}
                  toggler={
                    <div className='flex mb-0'>
                      <span className={`${fontSize} font-bold capitalize`}>
                        users:
                      </span>
                    </div>
                  }
                >
                  <div>
                    {firm.firm_users?.map((fu, subIndex) => {
                      return (
                        <div key={`summary_${firm.id}_${fu.user.id}`}>
                          <div
                            className={`flex ${subIndex === 0 ? '' : 'mt-2'}`}
                          >
                            <span className={`${fontSize} text-gray-400 mr-2`}>
                              Name:
                            </span>
                            <span
                              className={`${fontSize} font-bold`}
                              key={`name_${firm.id}_${fu.user.id}}`}
                            >
                              {fu?.user?.first_name} {fu?.user?.last_name}
                            </span>
                            {fu?.primary ? (
                              <div className='flex items-start ml-2'>
                                <div className='badge badge-primary font-bold'>
                                  Primary
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className='mt-2'>
                            <span className={`${fontSize} text-gray-400 mr-2`}>
                              Email:
                            </span>
                            <span
                              className={`${fontSize} font-bold`}
                              key={`email_${firm.id}_${fu.user.id}}`}
                            >
                              {fu?.user?.email_address}
                            </span>
                          </div>
                          {auth?.isAdmin ? (
                            <div className='mt-2'>
                              <span
                                className={`${fontSize} text-gray-400 mr-2`}
                              >
                                Job Title:
                              </span>
                              <span
                                className={`${fontSize} font-bold`}
                                key={`job_${firm.id}_${fu.user.id}}`}
                              >
                                {fu?.user?.job_title}
                              </span>
                            </div>
                          ) : null}
                          <hr
                            className={
                              firm.firm_users?.length === subIndex + 1
                                ? 'hidden'
                                : 'mt-2 mb-2'
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                </Accordion>
              </div>
            </div>
            {showHubspotLink ? (
              <Button
                color='warning'
                data-testid='hubspot-button'
                variant='outline'
                size='sm'
                onClick={() => {
                  openHubspotUser(firm?.hubspot_id || undefined);
                }}
                startIcon={<FontAwesomeIcon icon={faHubspot as IconProp} />}
              />
            ) : null}
          </div>
        </Accordion>
      ))}
    </>
  );
}

export default memo(FirmsAccordionList);
