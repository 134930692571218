import { useCallback, useEffect, useRef, useState } from 'react';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Client, FirmsSimpleDTO } from '@types';
import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';
import { debounce } from 'lodash';
import { fetcher } from '@/utils/queries';
import Select, { SingleValue } from 'react-select';

interface SelectOptions {
  id: number;
  label: string;
  firm_name?: string;
}

interface SelectFirmStepProps extends GenericWizardSteps {
  company: Client;
  disableNext: (value: boolean) => void;
  updateFirm: (value: FirmsSimpleDTO | undefined) => void;
}

export default function SelectFirmStep({
  company,
  disableNext,
  updateFirm,
}: SelectFirmStepProps) {
  const { auth }: IUserContext = useUserContext();
  const [searchMap, setSearchMap] = useState<SelectOptions[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedFirm, setSelectedFirm] = useState<
    SingleValue<SelectOptions> | undefined
  >();
  const firms = useRef<FirmsSimpleDTO[]>([]);

  useEffect(() => {
    disableNext(!selectedFirm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFirm]);

  const autocomplateOptions: SelectOptions[] = firms.current.map((firm) => ({
    id: firm.id || 0,
    label: firm.firm_name,
    firm_name: firm.firm_name,
  }));

  const apiFirmsByName = async (newQuery: string) => {
    try {
      const firmsListAPIUrl = '/api/firms';
      if (!newQuery) {
        return [];
      }
      const res = await fetcher<FirmsSimpleDTO[]>(
        `${firmsListAPIUrl}/${newQuery.toLowerCase()}`
      );
      return res;
    } catch {
      return [];
    }
  };

  const filterFirms = async (newQuery: string) => {
    firms.current = await apiFirmsByName(newQuery);
    const searchInputs: SelectOptions[] = firms.current
      .filter(
        (firm) =>
          firm.id !== auth?.id && !firm.clientsIds?.includes(company?.id || 0)
      )
      .map((firm) => ({
        id: firm.id || 0,
        label: firm.firm_name,
        firm_name: firm.firm_name,
      }));
    setSearchMap(searchInputs);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (newQuery: string) => {
      await filterFirms(newQuery);
    }, 500),
    []
  );

  const handleSearchQueryChange = useCallback(
    async (newQuery: string) => {
      setSearchQuery(newQuery);
      await debouncedSearch(newQuery);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, autocomplateOptions]
  );

  return (
    <div title='Select Firm'>
      <h1 className='text-lg font-bold'>Select Firm</h1>
      <br />
      <div>Search existing firms:</div>
      <div data-testid='select-firm-add'>
        <Select
          className='react-select-container border-accent mb-2'
          classNamePrefix='react-select'
          placeholder='Search firms ...'
          closeMenuOnSelect
          isClearable
          options={searchMap}
          inputValue={searchQuery}
          value={selectedFirm}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onInputChange={handleSearchQueryChange}
          onChange={(value) => {
            setSelectedFirm(value);
            if (value) {
              updateFirm({
                id: value.id,
                firm_name: value.firm_name,
              } as FirmsSimpleDTO);
            } else {
              updateFirm(undefined);
            }
            setSearchMap([]);
          }}
          filterOption={() => true} // Only filter querying the api
          onFocus={() => setSearchMap([])}
        />
      </div>
    </div>
  );
}
