import { memo, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Button } from 'react-daisyui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { DashboardCard } from '@/components/DashboardCard';
import type { User, Client, ClientUser } from '@types';
import AddInvestorUser from '@/components/AccountInvestorDetails/AddInvestorUser';
import { Accordion } from '@/components/Accordion/Accordion';
import SendLoginEmailToInvestorModal from '@/components/Modal/SendLoginEmailToInvestorModal';

const AccountInvestorDetails: React.FC<{
  investorUsersData: ClientUser[];
  startups: Client[];
  size?: 'sm' | 'md';
  currentUserEmail: string;
  test?: boolean;
}> = memo(
  ({
    investorUsersData,
    startups,
    size = 'md',
    currentUserEmail,
    test,
  }: {
    investorUsersData: ClientUser[];
    startups: Client[];
    size?: 'sm' | 'md';
    currentUserEmail: string;
    test?: boolean;
  }) => {
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const fontSize = size === 'md' ? 'text-base' : 'text-sm';

    const sendLogin = (user: User) => {
      setSelectedUser(user);
      setShowEmailModal(true);
    };

    const companyName =
      selectedUser?.clients && selectedUser.clients.length > 0
        ? selectedUser.clients[0].client_name
        : '';

    return (
      <div className='flex flex-wrap w-full m-4'>
        <div className='w-1/2 p-2'>
          <DashboardCard>
            <h2 className='text-base uppercase font-bold'>Users</h2>
            <ToastContainer />
            {!test ? (
              <>
                <div className='flex items-center space-x-4 mb-4'>
                  {' '}
                  <AddInvestorUser startups={startups} />
                </div>
                {showEmailModal && selectedUser ? (
                  <SendLoginEmailToInvestorModal
                    companyName={companyName}
                    showModal={showEmailModal}
                    email={selectedUser.email_address}
                    onClose={() => {
                      setShowEmailModal(false);
                      setSelectedUser(null);
                    }}
                  />
                ) : null}
              </>
            ) : null}
            {investorUsersData.map(
              (investorUser: ClientUser, index: number) => (
                <Accordion
                  key={`${investorUser?.users.email_address}`}
                  showBottomSeparator
                  open={index === 0}
                  toggler={
                    <div className='flex'>
                      <span className={`${fontSize} font-bold capitalize`}>
                        {`${investorUser?.users.first_name} ${investorUser?.users.last_name}`}
                      </span>
                    </div>
                  }
                >
                  <div className='flex'>
                    <div className='flex-grow'>
                      <div className='mt-2'>
                        <span className={`${fontSize} text-gray-400 mr-2`}>
                          Contact Email:
                        </span>
                        <span className={`${fontSize} font-bold`}>
                          {investorUser.users.email_address}
                        </span>
                      </div>
                      <div className='mt-2'>
                        <span className={`${fontSize} text-gray-400 mr-2`}>
                          Job Title:
                        </span>
                        <span className={`${fontSize} font-bold`}>
                          {investorUser?.users.job_title}
                        </span>
                      </div>
                      {investorUser?.users.email_address !==
                        currentUserEmail && (
                        <div className='flex justify-between w-full mt-2'>
                          <div className='flex items-center space-x-4'>
                            <Button
                              type='submit'
                              title='login'
                              color='accent'
                              size='sm'
                              className='btn btn-accent'
                              onClick={() => {
                                sendLogin(investorUser.users);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                className='mr-1'
                              />
                              Send login
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Accordion>
              )
            )}
          </DashboardCard>
        </div>
      </div>
    );
  }
);

export { AccountInvestorDetails };
