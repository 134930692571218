import type { FileItem, Folder } from '@types';
import { slugify } from '@/utils';

import { isEmpty } from 'lodash';
import { UserValue } from '@/components/FileBrowser/SharePermission';
import {
  getCheckedFileIdRecursive,
  setFilesIsChecked,
  transformFileItemtoFileItemExtended,
} from '@/components/UsersAccordionList/EditPermissionsModalSteps/utils';
import { post } from '@/utils/api';
import { MAX_SEARCH_RESULTS } from './constants';
import type { FileSearchItem } from './types';

// construct a stack of folders from slugified file names
export const getFolderStackFromPath = (
  pathStack: string[],
  rootFolder: Folder
): Folder[] => {
  const folderStack = [rootFolder];

  // eslint-disable-next-line no-restricted-syntax
  for (const folderName of pathStack) {
    if (!folderName) {
      return folderStack;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const fileItem of folderStack[folderStack.length - 1].files) {
      if (
        fileItem.type === 'folder' &&
        (fileItem.hubspotId === folderName ||
          slugify(fileItem.name) === folderName)
      ) {
        folderStack.push(fileItem as Folder);
        break;
      }
    }
  }

  return folderStack;
};

export const getPathFromFolderStack = (folderStack: Folder[]): string =>
  folderStack
    .map((folder) =>
      folder.hubspotId ? folder.hubspotId : slugify(folder.name)
    )
    .join('/');

export const getPathIdsFromFolderStack = (folderStack: Folder[]): string => {
  const slicedFolderStack = folderStack.slice(2); // Remove base folders
  return slicedFolderStack.map((folder) => folder.id).join('/');
};

export const isPathFromStack = (
  pathStack: string[],
  folderStack: Folder[]
): boolean => getPathFromFolderStack(folderStack) === pathStack.join('/');

const getLinkPath = (item: FileItem, linkPath = '') => {
  if (isEmpty(linkPath)) {
    return `shared-files/${item.hubspotId as string}`;
  }
  if (item.type === 'folder') {
    return `${linkPath}/${slugify(item.name)}`;
  }
  return linkPath;
};

export const buildSearchMap = (folder: Folder, path = '', linkPath = '') => {
  const searchMap: FileSearchItem[] = [];
  if (!folder.files) {
    return [];
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const item of folder.files) {
    const searchItem = {
      ...item,
      path: item.type === 'folder' ? `${path}/${slugify(item.name)}` : path,
      linkPath: getLinkPath(item, linkPath),
    };

    if (item.type === 'folder') {
      delete searchItem.files;
    }

    searchMap.push(searchItem);
  }

  // add the folder contents after the initial top-level items so we get a breadth-first search
  folder.files
    .filter((item) => item.type === 'folder')
    .forEach((item) => {
      searchMap.push(
        ...buildSearchMap(
          item as Folder,
          `${path}/${slugify(item.name)}`,
          getLinkPath(item, linkPath)
        )
      );
    });

  return searchMap;
};

export const searchFiles = (
  searchMap: FileSearchItem[],
  query: string,
  isClient?: boolean,
  limit = MAX_SEARCH_RESULTS
): FileSearchItem[] => {
  const splitQuery = query.toLowerCase().split(' ');

  const hits = searchMap.filter((item) => {
    const companyNameInPath = item.path.split('/')[1];
    return splitQuery.every(
      (string) =>
        item.name.toLowerCase().includes(string.toLowerCase()) ||
        (companyNameInPath.toLowerCase().includes(string.toLowerCase()) &&
          item.type === 'file' &&
          !isClient)
    );
  });

  return hits.slice(0, limit);
};

export const clipLastFolder = (path: string) => {
  const pathStack = path.split('/');

  if (pathStack.length > 1) {
    pathStack.pop();
  }

  return pathStack.join('/');
};

export const isRootFolder = (folder: Folder) => folder.hubspotId !== undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shouldDisplayFileItem = (auth: any, item: FileItem) => {
  const filesLength = item.files ? item.files.length : 0;
  const itemIsRootFolder = item.id === item.teamId;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  const isClientOrInvestor = auth?.isClient || auth?.isInvestor;
  return !(
    isClientOrInvestor &&
    !itemIsRootFolder &&
    item.type === 'folder' &&
    filesLength <= 0
  );
};

export const updateFilePermissions = async (
  usersToAdd: UserValue[],
  usersToRemove: UserValue[],
  fileOrFolder: FileItem,
  clientFolders: FileItem,
  clientId: number
) => {
  for (const user of usersToAdd) {
    const fileExt = transformFileItemtoFileItemExtended(
      clientFolders,
      user.value
    );
    const result = setFilesIsChecked(fileExt, fileOrFolder.id, true, true);
    const selectedFolderIds: string[] = [];
    getCheckedFileIdRecursive(result, selectedFolderIds);
    // eslint-disable-next-line no-await-in-loop
    await post(`permissions/files`, {
      filesIds: selectedFolderIds,
      clientId,
      userIds: [user.value],
    });
  }
  for (const user of usersToRemove) {
    const fileExt = transformFileItemtoFileItemExtended(
      clientFolders,
      user.value
    );
    const result = setFilesIsChecked(fileExt, fileOrFolder.id, false, true);
    const selectedFolderIds: string[] = [];
    getCheckedFileIdRecursive(result, selectedFolderIds);
    // eslint-disable-next-line no-await-in-loop
    await post(`permissions/files`, {
      filesIds: selectedFolderIds,
      clientId,
      userIds: [user.value],
    });
  }
};
