import { useEffect, useMemo, useState } from 'react';
import { getUserFullName } from '@/components/UserList/utils';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { Client } from '@types';
import { PopupButton } from '@typeform/embed-react';
import { Button } from 'react-daisyui';
import { GenericWizardSteps } from '@/components/GenericWizard/GenericWizard';

interface SelectUserTypeStepProps extends GenericWizardSteps {
  company: Client;
  disableNext: (value: boolean) => void;
  onClose: () => void;
}

export default function SelectUserTypeStep({
  company,
  disableNext,
  onClose,
}: SelectUserTypeStepProps) {
  const { auth }: IUserContext = useUserContext();
  const [internalUser, setInternalUser] = useState(false);

  useEffect(() => {
    disableNext(!internalUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalUser]);

  // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
  const FORM_ID: string = (import.meta.env || process.env)
    ?.VITE_TYPEFORM_ADD_NEW_USER_ROLE_HIDDEN;

  const getHiddenProps = useMemo<Record<string, string>>((): Record<
    string,
    string
  > => {
    const userFullName = auth?.id ? getUserFullName(auth) : '';
    const addedById = auth?.id.toString() || '';
    const companyId = company?.hubspot_id || '';
    const companyName = company?.client_name || '';

    return {
      company_name: companyName,
      added_by_full_name: userFullName,
      added_by_id: addedById,
      company_id: companyId,
      role: 'Internal',
    };
  }, [auth, company]);

  return (
    <>
      <div className='flex flex-col'>
        <div className='text font-bold capitalize'>Select User Type:</div>
      </div>
      <br />
      <div>
        <div className='text-sm'>
          Internal users have full access to your portal. Best for cofounders
          and employees who can be trusted with sensitive data.
        </div>
        <PopupButton
          id={FORM_ID}
          hidden={getHiddenProps}
          transitiveSearchParams={['']}
          className='btn btn-accent btn-sm my-2'
          key='add-user-investor-button'
          onClose={onClose}
        >
          Internal User
        </PopupButton>
        <div className='flex justify-center items-center'>
          <div style={{ borderBottom: '1px solid white', width: '50%' }} />
          <span className='px-2'>OR</span>
          <div style={{ borderBottom: '1px solid white', width: '50%' }} />
        </div>
        <div className='text-sm'>
          External users have limited portal access. You dictate which files,
          folders, and tabs they can see. Best for investors, advisors, and
          service providers.
        </div>
        <Button
          type='submit'
          title='login'
          color='accent'
          size='sm'
          className='btn btn-accent bg-transparentp-2 m-2 ml-0'
          data-testid='add-new-company-button'
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => setInternalUser(true)}
        >
          External User
        </Button>
      </div>
    </>
  );
}
