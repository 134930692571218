import { ReactElement, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Button, Select } from 'react-daisyui';
import { Client } from '@types';
import AddInvestorModal from '@/components/InvestorAddUserCard/AddInvestorModal';

interface Props {
  startups: Client[];
}

function AddInvestorUser({ startups = [] }: Props) {
  const [company, setCompany] = useState<Client | null>(null);
  const [showModal, setShowModal] = useState(false);

  const { Option } = Select;

  return (
    <div className='flex flex-col md:flex-row'>
      <FontAwesomeIcon
        icon={faUserPlus}
        size='2x'
        className='text-violet-600 mr-2 py-1'
      />
      <div className='text-base grow py-1 min-w-[270px]'>
        <div className='font-bold text-base'>Add a user for a company</div>
        <div className='text-base'>
          <Select
            defaultValue='default'
            className='select select-bordered select-accent w-[80%] mt-3'
            onChange={(selectedCompanyId: number) => {
              setCompany(
                startups?.find(
                  (startup) => startup.id === Number(selectedCompanyId)
                ) as Client
              );
            }}
          >
            <Option value='default' disabled>
              Select a company
            </Option>

            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */}
            {
              startups?.map((client: Client) => (
                <Option key={String(client.id)} value={String(client.id)}>
                  {client.client_name}
                </Option>
              )) as unknown as ReactElement
            }
          </Select>
        </div>
        {company?.id ? (
          <div className='mt-2'>
            <Button
              type='submit'
              title='login'
              color='accent'
              size='sm'
              className='btn btn-accent bg-transparentp-2 m-2 ml-0'
              data-testid='add-user-investor-button'
              onClick={() => setShowModal((prev) => !prev)}
            >
              Add User
            </Button>
            {showModal ? (
              <AddInvestorModal
                company={company}
                isOpen={showModal}
                onClose={() => setShowModal(false)}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default AddInvestorUser;
