import PageContainer from '@/components/PageContainer';
import { DashboardWrapper } from '@/components/DashboardWrapper';
import { AccountInvestorDetails } from '@/components/AccountInvestorDetails';
import { LoadingPage } from '@/pages/LoadingPage';
import ErrorAlert from '@/components/ErrorAlert/ErrorAlert';
import { IUserContext, useUserContext } from '@/context/UserContext';
import useInvestor from '@/hooks/useInvestor';
import type { Client } from '@types';

export default function AccountInvestor({ test = false }: { test?: boolean }) {
  const { auth }: IUserContext = useUserContext(); //  Use the context
  const { useStartups, useGetInvestorUsers } = useInvestor();

  const { data: startups } = useStartups();

  const {
    data: investorsUserData,
    isValidating,
    error,
  } = useGetInvestorUsers(Number(auth?.id));

  if (!investorsUserData || isValidating) {
    return <LoadingPage hasNav />;
  }

  if (error) {
    return (
      <PageContainer name='user-list'>
        <DashboardWrapper>
          <ErrorAlert
            isSuperAdmin={auth?.isSuperAdmin}
            error={error as unknown}
          />
        </DashboardWrapper>
      </PageContainer>
    );
  }

  return (
    <PageContainer name='investor-account'>
      <DashboardWrapper>
        <AccountInvestorDetails
          investorUsersData={investorsUserData}
          startups={startups as Client[]}
          currentUserEmail={auth?.email_address as string}
          test={test}
        />
      </DashboardWrapper>
    </PageContainer>
  );
}
