import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Input, Table } from 'react-daisyui';
import { useState, useMemo, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { OSPagination } from '@/components/Pagination/Pagination';
import { Sort } from '@/types';
import { DashboardCard } from '@/components/DashboardCard';
import { SortArrow } from '@/components/SortArrow/SortArrow';
import { getPageRecords, getNewSortState } from '@/utils';
import { Spinner } from '@/components/Spinner';
import { put } from '@/utils/queries';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { TableTransactionsRow } from './TableTransactionsRow';
import './TableTransactions.css';
import {
  QBOCategory,
  QBODetailedTransaction,
  QBOTransactionProperty,
} from '../../../../shared/types/qbo_data';

interface Props {
  transactions: QBODetailedTransaction[];
  uncategorizedCategories: QBOCategory[];
  // eslint-disable-next-line react/no-unused-prop-types
  test?: boolean;
}

function TableTransactions({ transactions, uncategorizedCategories }: Props) {
  const [searchText, setSearchText] = useState<string>('');
  const [sort, setSort] = useState<Sort>({ field: 'date', asc: true });
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(10);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const getSortValue = (transaction: QBODetailedTransaction) => {
    switch (sort.field) {
      case 'date':
        return new Date(transaction[sort.field]).getTime();
      case 'spent-received':
        return (
          (transaction.spent ? transaction.spent : 0) -
          (transaction.received ? transaction.received : 0)
        );
      default:
        return transaction[sort.field as QBOTransactionProperty];
    }
  };

  const sortTransactions = (): QBODetailedTransaction[] =>
    transactions
      ?.sort((c1, c2) => {
        const obj1 = getSortValue(c1);
        const obj2 = getSortValue(c2);
        if (obj1 && obj2 && obj1 > obj2) return sort.asc ? 1 : -1;
        return sort.asc ? -1 : 1;
      })
      .filter((transaction) =>
        `${(transaction.memo || '').toLowerCase()} ${(
          transaction.bank || ''
        ).toLowerCase()} 
        ${(transaction.payee || '').toLowerCase()}`.match(
          searchText.toLowerCase()
        )
      );

  const sortBy = (fieldName: string) => {
    const newSort = getNewSortState(fieldName, sort);
    setSort(newSort);
  };

  const records = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => sortTransactions(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sort, selectedPage, searchText, transactions]
  );

  useEffect(() => {
    setSelectedPage(1);
  }, [sort, recordsPerPage, searchText, transactions]);

  const isEven = (number: number) => {
    if (number % 2 === 0) return true;
    return false;
  };

  const updateTransaction = async (transaction: QBODetailedTransaction) => {
    setIsSaving(true);
    let newCategoryId = transaction.modified_qbo_categories_id;
    const uncategorizedCategoriesByQBOCompanyId =
      uncategorizedCategories.filter(
        (cat) => cat.qbo_company_id === Number(transaction.qbo_company_id)
      );

    if (!transaction.modified_qbo_categories_id) {
      newCategoryId =
        uncategorizedCategoriesByQBOCompanyId.length > 0
          ? uncategorizedCategoriesByQBOCompanyId[0].id
          : transaction.modified_qbo_categories_id;
    }

    const body = {
      modified_qbo_categories_id: newCategoryId,
      modified_memo: transaction.modified_memo,
    };
    try {
      let updatedTransaction = await put<QBODetailedTransaction>(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `transactions/${transaction.id}`,
        body
      );

      updatedTransaction = {
        ...updatedTransaction,
        modified_qbo_category:
          transaction.modified_qbo_categories_id &&
          uncategorizedCategoriesByQBOCompanyId.length > 0
            ? transaction.modified_qbo_category
            : { name: uncategorizedCategoriesByQBOCompanyId[0].name },
        qbo_category: transaction.qbo_category,
      };

      const index = transactions.findIndex(
        (tr) => tr.id === updatedTransaction.id
      );

      if (index !== -1) {
        // eslint-disable-next-line no-param-reassign
        transactions[index] = updatedTransaction;
      }

      setIsSaving(false);
      alertMessageSuccess('Transaction updated successfully.');
      return updatedTransaction;
    } catch (error) {
      setIsSaving(false);
      alertErrorMessage('Error while updating a transaction, try again later.');
      return null;
    }
  };

  return (
    <DashboardCard className='mt-4 p-0' cardBodyclassName='p-0'>
      <ToastContainer />
      <div
        className={`${isSaving ? 'spinner-container' : 'hidden'}`}
        id='spinner-total'
        data-testid='spinner-transaction'
      >
        <Spinner className='spinner' />
      </div>

      <div className='mt-3 ml-4 mb-1'>
        <div className='relative text-gray-600 focus-within:text-gray-400'>
          <span className='absolute inset-y-0 left-[260px] flex items-center pl-2'>
            <button
              type='submit'
              className='p-1 focus:outline-none focus:shadow-outline'
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </span>
          <Input
            data-testid='users-search-input'
            className='border-accent w-[300px]'
            placeholder='Search Transactions'
            size='sm'
            defaultValue={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
        </div>
      </div>

      <div>
        <Table zebra data-testid='transactions-list' id='table-transactions'>
          <Table.Head>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => sortBy('payee')}
              className='cursor-pointer'
              data-testid='transaction-payee-sorting'
            >
              <span className='text-base font-bold capitalize'>
                Payee
                <SortArrow sort={sort} fieldName='payee' />
              </span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => sortBy('memo')}
              className='cursor-pointer'
              data-testid='transaction-memo-sorting'
            >
              <span className='text-base font-bold capitalize'>
                Description
                <SortArrow sort={sort} fieldName='memo' />
              </span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => sortBy('date')}
              className='cursor-pointer'
              data-testid='transaction-date-sorting'
            >
              <span className='text-base font-bold capitalize'>
                Date
                <SortArrow sort={sort} fieldName='date' />
              </span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => sortBy('spent-received')}
              className='cursor-pointer'
              data-testid='transaction-spent-sorting'
            >
              <span className='text-base font-bold capitalize'>
                Amount
                <SortArrow sort={sort} fieldName='spent-received' />
              </span>
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              onClick={() => sortBy('bank')}
              className='cursor-pointer'
              data-testid='transaction-bank'
            >
              <span className='text-base font-bold capitalize'>
                Bank
                <SortArrow sort={sort} fieldName='bank' />
              </span>
            </div>
            <div className='cursor-pointer' data-testid='transaction-button' />
          </Table.Head>
          {/* {records.length > 0 ? () : null} */}
          {records.length > 0 ? (
            <tbody>
              {(
                getPageRecords(
                  records,
                  selectedPage,
                  recordsPerPage
                ) as QBODetailedTransaction[]
              ).map((transaction, index) => (
                <TableTransactionsRow
                  key={`${transaction.txn_id}-${
                    transaction.qbo_company_id as number
                  }`}
                  transaction={transaction}
                  isEven={isEven(index)}
                  onConfirm={updateTransaction}
                />
              ))}
            </tbody>
          ) : null}
        </Table>
      </div>
      {records.length === 0 && (
        <div className='text-center'>No Transactions Found</div>
      )}
      <OSPagination
        numPages={Math.ceil(records.length / recordsPerPage)}
        selectedPage={selectedPage}
        rowsPerPage={recordsPerPage}
        selectedRowsPerPage={[10, 20, 30, 40, 50]}
        onPageSelect={setSelectedPage}
        onSelectRowsChange={setRecordsPerPage}
      />
    </DashboardCard>
  );
}

export default TableTransactions;
export { TableTransactions };
