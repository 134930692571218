import { Accordion } from '@/components/Accordion/Accordion';
import GenericConfirmationModal from '@/components/Modal/GenericConfirmationModal';
import { IUserContext, useUserContext } from '@/context/UserContext';
import useAccountData from '@/hooks/useAccountData';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';
import { fetchDelete, patch, put } from '@/utils/api';
import { openHubspotUser } from '@/utils/hubspot';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHubspot } from '@fortawesome/free-brands-svg-icons';
import {
  faBell,
  faEnvelope,
  faLock,
  faMinusCircle,
  faTrash,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRollbar } from '@rollbar/react';
import { useEffect, useState } from 'react';
import { Button } from 'react-daisyui';
import { useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SendLoginEmailToInvestorModal from '@/components/Modal/SendLoginEmailToInvestorModal';
import useUsersList from '@/hooks/useUsersList';

// import TagInput from '@/components/TagInput/TagInput';
// import { useTags } from '@/hooks/useTags';
import { User } from '@types';
import EditPermissionsModal from '@/components/UsersAccordionList/EditPermissionsModalSteps/EditPermissionsModal';

interface RenderUsersProps {
  users: User[];
  name: 'internal' | 'external';
  sendLogin: (user: User) => void;
  sendTaskReminder: (email: string) => void;
  enable2FA?: (email: string, checked: boolean) => Promise<void>;
  showHubspotLink?: boolean;
  showDeleteButton?: boolean;
  fontSize: string;
  currentUserEmail: string;
  showEmailModal: boolean;
  companyName: string;
  companyEmail: string;
  selectedUser: User | null;
  onClose: () => void;
}

function RenderUsers({
  users,
  name,
  sendLogin,
  sendTaskReminder,
  showHubspotLink,
  showDeleteButton,
  fontSize,
  currentUserEmail,
  showEmailModal,
  companyName,
  companyEmail,
  selectedUser,
  onClose,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  enable2FA,
}: RenderUsersProps) {
  const { auth, impersonatingAccountId }: IUserContext = useUserContext();
  const [showModalEditAccess, setShowModalEditAccess] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const editPermissionModalOpen = params.get('edit-permissions-modal') || '/';
  const isClientUser = params.get('client-user') || null;
  if (isClientUser) {
    params.delete('edit-permissions-modal');
    params.delete('client-user');
    const newUrl = `${window.location.origin}/account`;
    window.history.replaceState({}, '', newUrl);
  }
  const { refreshUsersListByClientId } = useUsersList();
  const [showModalClient, setShowModalClient] = useState(false);
  const [showModalInvestor, setShowModalInvestor] = useState(false);
  const [showModalPrimary, setShowModalPrimary] = useState(false);
  const [modalUser, setModalUser] = useState<User | null>(null);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  useEffect(() => {
    setFilteredUsers(
      users.filter((user) => !user.role?.includes('admin') && user.enabled)
    );
  }, [users]);

  const [modalClientId, setModalClientId] = useState<number | undefined>(
    undefined
  );
  const { refreshAccountData } = useAccountData();

  const { clientId, userId } = useParams();
  const rollbar = useRollbar();
  // const { useGetTags, upsertTags, deleteTag } = useTags({
  //   clientId: Number(clientId),
  // });

  // const { data, isLoading } = useGetTags;
  // const handleTagChange = (
  //   email: string,
  //   tags: string[],
  //   updatedTag: string
  // ) => {
  //   upsertTags(updatedTag, Number(clientId), email)
  //     .then(() => {
  //       updateAlertMessage('Tag added successfully');

  //       // refreshAccountData(Number(clientId))
  //       //   .then(() => {
  //       //     setShowModal(false);
  //       //     setModalUser(null);
  //       //   })
  //       //   .catch(() => {
  //       //     setShowModal(false);
  //       //     setModalUser(null);
  //       //   });
  //       setShowModal(false);
  //     })
  //     .catch((ex) => {
  //       rollbar.error(ex as Error);
  //       updateAlertMessage('Tag update operation failed');
  //       setShowModal(false);
  //     });
  // };
  // const handleTagDelete = (email: string, tag: string) => {
  //   deleteTag(tag, Number(clientId), email)
  //     .then(() => {
  //       updateAlertMessage('Tag deleted successfully');
  //     })
  //     .catch((ex) => {
  //       rollbar.error(ex as Error);
  //       updateAlertMessage('Tag deletion operation failed');
  //     });
  // };

  const onShowModalClient = (user: User, show = true) => {
    setShowModalClient(show);
    if (show) {
      setModalUser(user);
    }
  };

  const onShowModalInvestor = (user: User, show = true) => {
    setShowModalInvestor(show);
    if (show) {
      setModalUser(user);
    }
  };

  const onShowModalPrimary = (user: User, show = true) => {
    setShowModalPrimary(show);
    if (show) {
      setModalUser(user);
    }
  };

  const onShowModalEditAccess = (user: User, show = true) => {
    if (show) {
      const clientIdValue: number | undefined = clientId
        ? Number(clientId)
        : impersonatingAccountId || auth?.clients[0].id;
      setModalClientId(clientIdValue);
      setModalUser(user);
    }
    setShowModalEditAccess(show);
  };

  useEffect(() => {
    if (users && clientId && userId && editPermissionModalOpen === 'open') {
      const user = filteredUsers.find((e) => e.id === Number(userId));
      if (user) {
        onShowModalEditAccess(user, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, userId, editPermissionModalOpen, filteredUsers]);

  const disableClientUser = async (
    email: string | undefined,
    hubspotId: string | undefined
  ) => {
    try {
      await put(
        `users/disableUserWithHubSpotAccount${
          auth?.isClient ? `/${auth.clients?.[0]?.id}` : ''
        }`,
        { email, hubspotId }
      );
      const clientIdValue: number | undefined = clientId
        ? Number(clientId)
        : impersonatingAccountId || auth?.clients[0].id;
      alertMessageSuccess('User Disabled Successfully!');

      await refreshAccountData(Number(clientIdValue));
      await refreshUsersListByClientId(Number(clientIdValue));
    } catch (error) {
      rollbar.error('RenderUser.tsx - disableClientUser', error as Error);

      alertErrorMessage('Operation failed.');
    } finally {
      setShowModalClient(false);
    }
  };
  const disableInvestor = async () => {
    try {
      const clientIdValue: number | undefined = clientId
        ? Number(clientId)
        : impersonatingAccountId || auth?.clients[0].id;
      await fetchDelete('investors/remove-investor-from-company', {
        investorEmail: modalUser?.email_address,
        clientId: clientIdValue,
      });
      await refreshAccountData(Number(clientIdValue));
      await refreshUsersListByClientId(Number(clientIdValue));
      alertMessageSuccess('Investor removed Successfully!');
    } catch (error) {
      rollbar.error(
        `RenderUser.tsx - disableInvestor: Error removing investor access ${
          modalUser?.email_address as string
        }`,
        error as Error
      );

      alertErrorMessage('Operation failed.');
    } finally {
      setShowModalInvestor(false);
    }
  };

  const updatePrimaryProperty = async () => {
    const clientIdValue: number | undefined = clientId
      ? Number(clientId)
      : impersonatingAccountId || auth?.clients[0].id;
    const primaryValue = !modalUser?.primary || false;
    try {
      await patch(`users/update-primary`, {
        userId: modalUser?.id,
        clientId: clientIdValue,
        primary: primaryValue,
      });
      await refreshAccountData(Number(clientIdValue));
      await refreshUsersListByClientId(Number(clientIdValue));
      const clienthasOtherPrimary =
        primaryValue ||
        users.some((user) => user.primary && user.id !== modalUser?.id);
      if (!clienthasOtherPrimary) {
        alertMessageSuccess(
          `User updated. No primary user exist for ${companyName}`
        );
      } else {
        alertMessageSuccess('User updated!');
      }
    } catch (error) {
      rollbar.error(
        `RenderUser.tsx - updatePrimaryProperty: Error updating primary ${
          modalUser?.email_address as string
        } for Client: ${clientIdValue as number}`,
        error as Error
      );

      alertErrorMessage('Operation failed.');
    } finally {
      setShowModalPrimary(false);
    }
  };

  return (
    <>
      <ToastContainer />
      {showDeleteButton ? (
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={() =>
            disableClientUser(
              modalUser?.email_address,
              modalUser?.hubspot_id || undefined
            ).catch((ex) =>
              rollbar.error(
                `Error disabling user ${modalUser?.email_address as string}`,
                ex as Error
              )
            )
          }
          onCloseAction={() => setShowModalClient(false)}
          showModal={showModalClient}
          modalMessage={`Are you sure you want to delete this user 
            ${modalUser?.email_address as string}?`}
        />
      ) : null}

      {!auth?.isInvestor && showModalEditAccess ? (
        <EditPermissionsModal
          clientId={modalClientId as number}
          user={modalUser}
          isOpen={showModalEditAccess}
          onClose={() => setShowModalEditAccess(false)}
        />
      ) : null}
      {
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={() =>
            disableInvestor().catch((ex) => rollbar.error(ex as Error))
          }
          onCloseAction={() => setShowModalInvestor(false)}
          showModal={showModalInvestor}
          modalMessage={`Are you sure you want to remove access to this investor 
            ${modalUser?.email_address as string}?`}
        />
      }
      {
        <GenericConfirmationModal
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onAcceptAction={() =>
            updatePrimaryProperty().catch((ex) => rollbar.error(ex as Error))
          }
          onCloseAction={() => setShowModalPrimary(false)}
          showModal={showModalPrimary}
          modalMessage={`Are you sure you want to change the user
            ${modalUser?.email_address as string} as a ${
            modalUser?.primary ? 'Regular User' : 'Primary User'
          }?`}
        />
      }
      {filteredUsers.length > 0 ? (
        <h2 className={`text-xl ${fontSize} my-2 capitalize`}>{name}</h2>
      ) : null}
      {showEmailModal && selectedUser ? (
        <SendLoginEmailToInvestorModal
          companyName={companyName}
          companyEmail={companyEmail}
          showModal={showEmailModal}
          email={selectedUser.email_address}
          onClose={onClose}
        />
      ) : null}
      {filteredUsers.map((user: User, index: number) => (
        <Accordion
          key={`${user?.email_address} ${
            (user?.role === 'investor' || true) as unknown as string
          }`}
          showBottomSeparator
          open={index === 0}
          toggler={
            <div className='flex'>
              <span className={`${fontSize} font-bold capitalize`}>
                {`${user?.first_name} ${user?.last_name}`}
              </span>
              {user?.primary ? (
                <div className='flex items-start ml-2'>
                  <div className='badge badge-primary font-bold'>Primary</div>
                </div>
              ) : null}
            </div>
          }
        >
          <div className='flex'>
            <div className='flex-grow'>
              <div className='mt-2'>
                <span className={`${fontSize} text-gray-400 mr-2`}>
                  Contact Email:
                </span>
                <span className={`${fontSize} font-bold`}>
                  {user.email_address}
                </span>
              </div>
              <div className='mt-2'>
                <span className={`${fontSize} text-gray-400 mr-2`}>
                  Job Title:
                </span>
                <span className={`${fontSize} font-bold`}>
                  {user?.job_title}
                </span>
              </div>
              {user.added_by && (auth?.isClient || impersonatingAccountId) ? (
                <div className='mt-2'>
                  <span className={`${fontSize} text-gray-400 mr-2`}>
                    Added: By
                  </span>
                  <span className={`${fontSize} font-bold`}>
                    {user?.added_by || '-'}
                  </span>
                </div>
              ) : null}
              {user?.email_address !== currentUserEmail && (
                <>
                  {/* <div className='flex items-center mt-4 mb-4'>
                    <TagInput
                      suggestions={data?.map((t) => t.name) || []}
                      isLoading={isLoading}
                      onChange={(tags, tag) => {
                        handleTagChange(user?.email_address, tags, tag);
                      }}
                      values={user?.tags?.map((t) => t.name) || []}
                      onDelete={(tag) => {
                        handleTagDelete(user?.email_address, tag);
                      }}
                    />
                  </div> */}
                  <div className='flex justify-between w-full mt-2'>
                    <div className='flex items-center space-x-4'>
                      <Button
                        type='submit'
                        title='login'
                        color='accent'
                        size='sm'
                        className='btn btn-accent'
                        onClick={() => {
                          sendLogin(user);
                        }}
                      >
                        <FontAwesomeIcon icon={faEnvelope} className='mr-1' />
                        Send login
                      </Button>
                      {auth?.isAdmin && user.role !== 'investor' ? (
                        <Button
                          type='submit'
                          title='reminder'
                          color='accent'
                          size='sm'
                          className='btn btn-accent'
                          onClick={() => {
                            sendTaskReminder(user?.email_address);
                          }}
                        >
                          <FontAwesomeIcon icon={faBell} className='mr-1' />
                          Send task reminder
                        </Button>
                      ) : null}
                      {user.role === 'investor' &&
                      (auth?.primary || auth?.isAdmin) &&
                      !auth?.isInvestor ? (
                        <Button
                          type='submit'
                          title='permissions'
                          color='accent'
                          size='sm'
                          className='btn btn-accent'
                          onClick={() => {
                            onShowModalEditAccess(user);
                          }}
                        >
                          <FontAwesomeIcon icon={faLock} className='mr-1' />
                          Edit Access
                        </Button>
                      ) : null}
                      {/* <input
                      type='checkbox'
                      checked={user.mfaEnable}
                      style={!enable2FA ? { visibility: 'hidden' } : undefined}
                      className='checkbox checkbox-accent mr-1'
                      onChange={(e) => {
                        enable2FA(user.contact_email, e.target.checked).catch(
                          (ex) => rollbar.error(ex as Error)
                        );
                      }}
                    />
                    &nbsp; 2FA Enabled */}
                    </div>
                  </div>
                </>
              )}
            </div>
            {showHubspotLink && user.hubspot_id && (
              <div className='flex items-center mt-16 mr-0'>
                <Button
                  color='warning'
                  data-testid='hubspot-button'
                  variant='outline'
                  size='sm'
                  onClick={() => {
                    openHubspotUser(user?.hubspot_id || undefined);
                  }}
                  startIcon={<FontAwesomeIcon icon={faHubspot as IconProp} />}
                />
              </div>
            )}
            {user?.role === 'investor' ? (
              <div className='flex items-center mt-16 ml-2'>
                <Button
                  color='error'
                  data-testid='remove-investor-button'
                  variant='outline'
                  size='sm'
                  className='ml-1'
                  onClick={() => {
                    onShowModalInvestor(user);
                  }}
                  startIcon={
                    <FontAwesomeIcon icon={faMinusCircle as IconProp} />
                  }
                />
              </div>
            ) : null}
            {showDeleteButton &&
            !(user?.role === 'investor') &&
            currentUserEmail !== user.email_address ? (
              <div className='flex items-center mt-16 ml-2'>
                <Button
                  color='error'
                  data-testid='disable-button'
                  variant='outline'
                  size='sm'
                  className='ml-1'
                  onClick={() => {
                    onShowModalClient(user);
                  }}
                  startIcon={<FontAwesomeIcon icon={faTrash as IconProp} />}
                />
              </div>
            ) : null}
            {auth?.isAdmin && user.role === 'client' ? (
              <div className='flex items-center mt-16 ml-2'>
                <Button
                  color={user?.primary ? 'error' : 'primary'}
                  data-testid='change-primary'
                  variant='outline'
                  size='sm'
                  className='ml-1'
                  onClick={() => {
                    onShowModalPrimary(user);
                  }}
                  title={user?.primary ? 'Set as Regular' : 'Set as Primary'}
                  startIcon={<FontAwesomeIcon icon={faStar as IconProp} />}
                />
              </div>
            ) : null}
          </div>
        </Accordion>
      ))}
    </>
  );
}

export default RenderUsers;
