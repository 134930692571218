import { GenericModal } from '@/components/Modal';
import { PopupButton } from '@typeform/embed-react';
import { IUserContext, useUserContext } from '@/context/UserContext';
import { getUserFullName } from '@/components/UserList/utils';
import { Client, SimpleUserDto } from '@types';
import { useState, useCallback, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { fetcher } from '@/utils/queries';
import { Button } from 'react-daisyui';
import Select, { MultiValue } from 'react-select';
import { patch } from '@/utils/api';
import { useRollbar } from '@rollbar/react';
import { alertErrorMessage, alertMessageSuccess } from '@/utils/alerts';

interface SelectOptions {
  value: number;
  label: string;
}

export default function AddInvestorModal({
  company,
  isOpen,
  onClose,
}: {
  company: Client;
  isOpen: boolean;
  onClose: () => void;
}) {
  const rollbar = useRollbar();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [optionsSelected, setOptionsSelected] = useState<
    MultiValue<SelectOptions>
  >([]);
  const [searchMap, setSearchMap] = useState<SelectOptions[]>([]);
  const { auth }: IUserContext = useUserContext(); // Use the context
  const users = useRef<SimpleUserDto[]>([]);

  const autocomplateOptions: SelectOptions[] = users.current.map((user) => ({
    value: user.id || 0,
    label: `${user.first_name} ${user.last_name}`,
  }));

  // eslint-disable-next-line  @typescript-eslint/no-unsafe-assignment
  const FORM_ID: string = (import.meta.env || process.env)
    ?.VITE_TYPEFORM_ADD_NEW_USER_ROLE_HIDDEN;

  const getHiddenProps = useMemo<Record<string, string>>((): Record<
    string,
    string
  > => {
    const userFullName = auth?.id ? getUserFullName(auth) : '';
    const addedById = auth?.id.toString() || '';
    const companyId = company?.hubspot_id || '';
    const companyName = company?.client_name || '';

    return {
      company_name: companyName,
      added_by_full_name: userFullName,
      added_by_id: addedById,
      company_id: companyId,
      role: 'External',
    };
  }, [auth, company]);

  const apiUsersByEmailCall = async (newQuery: string) => {
    try {
      const role = 'investor';
      const usersListAPIUrl = '/api/users/list';
      if (!newQuery) {
        return [];
      }
      const res = await fetcher<SimpleUserDto[]>(
        `${usersListAPIUrl}/${role}/${newQuery.toLowerCase()}`
      );
      return res;
    } catch {
      return [];
    }
  };

  const filterUsers = async (newQuery: string) => {
    users.current = await apiUsersByEmailCall(newQuery);
    const searchInputs: SelectOptions[] = users.current
      .filter(
        (user) =>
          user.id !== auth?.id &&
          !user.clientsIds?.includes(company?.id || 0) &&
          (user.firmsIds?.length === 0 || !user.firmsIds)
      )
      .map((user) => ({
        value: user.id || 0,
        label: `${user.first_name} ${user.last_name}`,
      }));
    setSearchMap(searchInputs);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (newQuery: string) => {
      await filterUsers(newQuery);
    }, 500),
    []
  );

  const handleSearchQueryChange = useCallback(
    async (newQuery: string) => {
      setSearchQuery(newQuery);
      await debouncedSearch(newQuery);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch, autocomplateOptions]
  );

  const onSave = async () => {
    const body = {
      usersIds: optionsSelected.map((option) => option.value),
      clientId: company?.id,
    };
    try {
      await patch('users/addClientAndDuplicate', body);
      alertMessageSuccess('User/s added successfully');
      setOptionsSelected([]);
      setSearchMap([]);
      onClose();
    } catch (ex) {
      rollbar.error('AddInverstorModal.tsx ~ onSave', ex as Error);
      alertErrorMessage('Something went wrong. Please try again.');
    }
  };

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} isFullWidth={false}>
      <h1 className='text-lg font-bold'>Select User</h1>
      <div className='text-sm text-gray-500'>
        Please be aware that any user you add will be granted the same access
        permissions as your account.
      </div>
      <br />
      <div>Search existing investors by email address:</div>
      <div data-testid='select-investor-add'>
        <Select
          isMulti
          className='react-select-container border-accent mb-2'
          classNamePrefix='react-select'
          placeholder='Search users ...'
          closeMenuOnSelect={false}
          options={searchMap}
          inputValue={searchQuery}
          value={optionsSelected}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onInputChange={handleSearchQueryChange}
          onChange={(value) => {
            setOptionsSelected(value);
            setSearchMap([]);
          }}
          filterOption={() => true} // Only filter querying the api
          onFocus={() => setSearchMap([])}
        />
      </div>
      <Button
        type='submit'
        title='login'
        color='accent'
        size='sm'
        className='btn btn-accent bg-transparentp-2 m-2 ml-0'
        data-testid='add-new-company-button'
        disabled={optionsSelected.length === 0}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={() => onSave()}
      >
        Add User
      </Button>
      <br />
      <br />
      <div className='flex justify-center items-center'>
        <div style={{ borderBottom: '1px solid white', width: '50%' }} />
        <span className='px-2'>OR</span>
        <div style={{ borderBottom: '1px solid white', width: '50%' }} />
      </div>
      <br />
      <div>Add a new Investor:</div>
      <PopupButton
        id={FORM_ID}
        hidden={getHiddenProps}
        transitiveSearchParams={['']}
        className='btn btn-accent btn-sm my-2'
        key='add-user-investor-button'
      >
        Complete Form
      </PopupButton>
    </GenericModal>
  );
}
